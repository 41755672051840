<template>
  <div>
    <Table :status="1" />
  </div>
</template>

<script>
import Table from './Table'
export default {
  components: {
    Table
  }
}
</script>

<style>

</style>